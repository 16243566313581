import React from 'react';
import PropTypes from 'prop-types';

import EventMonitor from 'components/EventMonitor/EventMonitor';
import styles from './SiteLinks.scss';

const SiteLinks = ({ data }) => (
  <div className={styles.siteLinks} data-testid="site-links">
    {data.links !== undefined &&
      data.links !== null &&
      data.links.map((link, index) => {
        const eventProps = {
          actionType: 'redirect',
          originComponent: 'footer link',
          shortDescription: `footer link: site links: ${link.title}`,
          key: `footer-site-links-${index}`,
        };
        const linkProps = {
          alt: link.title,
          className: styles.link,
          href: link.href,
          target: link.popup ? '_blank' : '_self',
          'data-testid': 'site-link',
        };

        return (
          <EventMonitor {...eventProps}>
            <a {...linkProps}>
              <span>{link.title}</span>
            </a>
          </EventMonitor>
        );
      })}
  </div>
);

SiteLinks.propTypes = {
  data: PropTypes.shape({
    links: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        href: PropTypes.string,
        popup: PropTypes.bool,
      }),
    ),
  }),
};

SiteLinks.defaultProps = {
  data: null,
};

SiteLinks.displayName = 'SiteLinks';

export default SiteLinks;
