import { loadableReady } from '@loadable/component';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { AnalyticsProvider } from '@johnlewispartnership/wtr-website-analytics';
import { getAnalytics } from 'analytics';
import { PreloadedDataProvider } from 'contexts/PreloadedData';
import getPreloadedData from 'utils/dataPreloader/getPreloadedData';

import SiteFooter from 'components/Footer/SiteFooter';

const preloadedData = getPreloadedData('footer');

const renderApp = () => {
  const rootElement = createRoot(document.getElementById('common-footer-root')!);

  rootElement.render(
    <BrowserRouter>
      <AnalyticsProvider
        // eslint-disable-next-line no-underscore-dangle
        initialEvents={window.__WTR_COMMON__?.footer?.analytics}
        analytics={getAnalytics()}
      >
        <PreloadedDataProvider preloadedData={preloadedData}>
          <SiteFooter isNewHeaderShowing={false} />
        </PreloadedDataProvider>
      </AnalyticsProvider>
    </BrowserRouter>,
  );
};

loadableReady(
  () => {
    renderApp();
  },
  {
    namespace: 'common-footer',
  },
);
