import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { Accordion } from '@johnlewispartnership/wtr-ingredients/dist/ingredients/Accordion';
import { Container } from '@johnlewispartnership/wtr-ingredients/dist/foundations/grid';
import Typography from '@johnlewispartnership/wtr-ingredients/dist/foundations/typography';
import BackToTop from 'components/BackToTop';
import FooterBadges from 'components/Footer/FooterBadges';
import FooterLinkList from 'components/Footer/FooterLinkList';
import SiteLinks from 'components/Footer/SiteLinks';
import SocialLinks from 'components/Footer/SocialLinks';
import AppLinks from 'components/Footer/AppLinks';
import searchService from 'services/search';
import { usePreloadedData } from 'contexts/PreloadedData';
import styles from './SiteFooter.scss';

const cookies = new Cookies();

export const fetchContent = async hasTokenSession =>
  searchService.getResults('taxonomy/footer', hasTokenSession);

const SiteFooter = ({ isNewHeaderShowing }) => {
  const { preloadedData } = usePreloadedData();
  const [footerData, setFooterData] = useState(preloadedData?.results);

  useEffect(() => {
    if (typeof preloadedData?.results === 'undefined') {
      // fetching cookie value instead of the auth provider to avoid having to make an extra token call when initialising the provider
      const hasTokenSession = cookies.get('X-Has-Token-Session', { doNotParse: true }) === 'true';

      fetchContent(hasTokenSession).then(data => {
        setFooterData(data.results);
      });
    }
  }, [preloadedData?.results, setFooterData]);

  if (!footerData) {
    return null;
  }

  const { support, about, shops, social, apps, site } = footerData;

  return (
    <div className={styles.footer}>
      <Accordion>
        <Typography element="h2" className={styles.srOnly}>
          Footer
        </Typography>
        <Container data-testid="small-layout" className={`${styles.linksLists} ${styles.noPrint}`}>
          {support && <FooterLinkList section={support} accordion />}
          {about && <FooterLinkList section={about} accordion />}
          {shops && (
            <FooterLinkList section={shops} accordion isNewHeaderShowing={isNewHeaderShowing} />
          )}
        </Container>
        <Container
          data-testid="large-layout"
          className={`${styles.linksLists} ${styles.large} ${styles.noPrint}`}
        >
          {support && <FooterLinkList section={support} />}
          {about && <FooterLinkList section={about} />}
          {shops && <FooterLinkList section={shops} isNewHeaderShowing={isNewHeaderShowing} />}
          <div>
            {social && <SocialLinks data={social} />}
            {apps && <AppLinks data={apps} />}
          </div>
        </Container>
        {site && <SiteLinks className={styles.noPrint} data={site} />}
        {social && <SocialLinks className={styles.mobileSocialLinks} data={social} />}
        {apps && <AppLinks className={styles.mobileAppLinks} data={apps} />}
        <FooterBadges data={footerData} />
        <BackToTop />
      </Accordion>
    </div>
  );
};

SiteFooter.propTypes = {
  isNewHeaderShowing: PropTypes.bool,
};

SiteFooter.defaultProps = {
  isNewHeaderShowing: false,
};

SiteFooter.displayName = 'SiteFooter';

export default SiteFooter;
