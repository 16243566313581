import PropTypes from 'prop-types';
import React from 'react';
import styles from './EventMonitor.scss';

const getClasses = classNames => {
  let classes = classNames || [];

  if (classes.constructor !== Array) {
    classes = [classes];
  }

  return [styles.gtm].concat(classes).join(' ');
};

const EventMonitor = ({
  actionType,
  ariaLabel,
  children,
  classNames,
  customData,
  disableAccessibility,
  gtmDescription,
  originComponent,
  shortDescription,
  tabIndex,
}) => {
  const modifiedChildren = React.Children.map(children, child => {
    // TODO: Nuke the entire site from orbit. It's the only way to be sure.
    if (
      child.type.name &&
      (child.type.name || '').toLowerCase() !== 'constructor' &&
      !['Link', 'Button'].includes(child.type.name) &&
      !(child.to && child.className && child.className.includes('link'))
    ) {
      return child;
    }

    const newProps = {
      'data-customdata':
        customData && typeof customData !== 'string' ? JSON.stringify(customData) : customData,
      'data-actiontype': actionType,
      'data-origincomponent': originComponent,
      'data-shortdescription': shortDescription,
    };

    if (!disableAccessibility) {
      newProps['aria-label'] = ariaLabel || shortDescription;
    }

    if (tabIndex) {
      newProps.tabIndex = tabIndex;
    }

    return React.cloneElement(child, newProps);
  });

  return (
    <div
      data-gtm={gtmDescription || 'inner element mapped for GTM'}
      className={getClasses(classNames)}
    >
      {modifiedChildren}
    </div>
  );
};

const allowedActions = [
  'book',
  'close',
  'edit',
  'filter',
  'insert',
  'list',
  'next',
  'open',
  'previous',
  'redirect',
  'remove',
  'reset',
  'search',
];

EventMonitor.propTypes = {
  actionType: PropTypes.oneOf(allowedActions),
  ariaLabel: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  classNames: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  customData: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  disableAccessibility: PropTypes.bool,
  gtmDescription: PropTypes.string,
  originComponent: PropTypes.string,
  shortDescription: PropTypes.string.isRequired,
  tabIndex: PropTypes.string,
};

EventMonitor.defaultProps = {
  disableAccessibility: false,
  customData: '',
  actionType: undefined,
  ariaLabel: undefined,
  classNames: undefined,
  gtmDescription: undefined,
  originComponent: undefined,
  tabIndex: undefined,
};

export default EventMonitor;
