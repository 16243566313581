import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { Logo as WaitroseLogo } from '@johnlewispartnership/wtr-ingredients/dist/ingredients/Logo';
import { TextLink } from '@johnlewispartnership/wtr-ingredients/dist/ingredients/TextLink';
import EventMonitor from 'components/EventMonitor/EventMonitor';
import config from 'config';

import styles from './FooterBadges.scss';

const mhraLinkIsValid = data => data.mhra && data.mhra.href && data.mhra.src;

const FooterBadges = memo(({ data, data: { copyright, mhra = {} } }) => (
  <div className={styles.badges}>
    <div className={styles.logoWrapper}>
      <span className={styles.logo}>
        <WaitroseLogo optimised width={100} />
      </span>
      <small className={styles.copyright}>{copyright}</small>
    </div>
    {mhraLinkIsValid(data) && (
      <div className={styles.mhra}>
        <span id="mhra-label" className={styles.mhraLabel}>
          {mhra.alt}
        </span>
        <EventMonitor
          actionType="redirect"
          originComponent="footer link"
          shortDescription={`footer link: ${mhra.alt}`}
        >
          <TextLink
            underline={false}
            href={mhra.href}
            aria-labelledby="mhra-label"
            className={styles.verifyLink}
          >
            <img loading="lazy" src={`${config.cfDomain}${mhra.src}`} alt={mhra.alt} />
          </TextLink>
        </EventMonitor>
      </div>
    )}
  </div>
));

FooterBadges.propTypes = {
  data: PropTypes.shape({
    copyright: PropTypes.string,
    mhra: PropTypes.shape({
      alt: PropTypes.string,
      href: PropTypes.string,
      src: PropTypes.string,
    }),
  }),
};

FooterBadges.defaultProps = {
  data: {},
};

FooterBadges.displayName = 'FooterBadges';

export default FooterBadges;
