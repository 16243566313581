import hash from 'object-hash';
import { cleanCache } from './clean-cache';

const INTERVAL_FROM_ENV = parseInt(process.env.ASYNC_CACHE_CLEANUP_INTERVAL, 10);
const CLEANUP_INTERVAL =
  !Number.isNaN(INTERVAL_FROM_ENV) && INTERVAL_FROM_ENV > 0 ? INTERVAL_FROM_ENV : 60000;

const cache = new Map();

// Deletes dangling expired cache entries every CLEANUP_INTERVAL milliseconds
const cacheCleanupTimeout = setInterval(() => cleanCache(cache), CLEANUP_INTERVAL);

export const shutdown = () => {
  clearInterval(cacheCleanupTimeout);
};

/**
 * Wrap your expensive functions in asyncCache() to use a short-term in-memory cache. Dangling cache entries are
 * cleaned every one minute. To change that, set the ASYNC_CACHE_CLEANUP_INTERVAL env variable to the desired amount of milliseconds.
 *
 * <pre><code>
 * function myExpensiveFunc(url) {
 *  return fetch(url); // Do expensive server call here
 * }
 *
 * const myExpensiveData = await asyncCache(myExpensiveFunc, ['http://some.url.somewhere/']);
 * </code></pre>
 *
 * @param {(...args: any) => any} callback - The function of which's return value we're caching
 * @param {any[]} args - An array of the arguments to apply to the given callback function
 * @param {Object} options - Cache options object
 * @param {number=} options.maxAge - Cache TTL value (time an object is stored in cache before it's discarded)
 * @param {(entry: any) => void=} options.onCacheHit - Callback function to execute when a value is read from the cache
 * @param {({expires: number, value: any}) => void=} options.onCallThrough - Callback function to execute when a value is written into the cache
 * @param {any[]=} options.dependencies - If dependencies change, it means you want to call through, and not use the cached value (similarly to react hooks). If no dependencies are specified, the arguments will be used for the same.
 */
const asyncCache = async (
  callback,
  args,
  { maxAge = 10000, onCacheHit, onCallThrough, dependencies } = {},
) => {
  const cacheHash = hash(dependencies || args);

  const now = new Date().getTime();

  const cacheEntryForCallback = cache.get(callback) || {};

  if (!cacheEntryForCallback[cacheHash] || cacheEntryForCallback[cacheHash].expires <= now) {
    const newValue = await callback(...args);
    cache.set(callback, {
      ...cache.get(callback),
      [cacheHash]: {
        expires: now + maxAge,
        value: newValue,
      },
    });

    if (onCallThrough) {
      onCallThrough({
        expires: now + maxAge,
        value: newValue,
      });
    }

    return newValue;
  }

  if (onCacheHit) {
    onCacheHit(cacheEntryForCallback[cacheHash]);
  }

  return cacheEntryForCallback[cacheHash].value;
};

export default asyncCache;
