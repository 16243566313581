const cleanCacheForCallback = (cacheEntryForCallback, now) =>
  Object.keys(cacheEntryForCallback).reduce((newCache, key) => {
    if (cacheEntryForCallback[key].expires <= now) {
      return newCache;
    }
    return {
      ...newCache,
      [key]: cacheEntryForCallback[key],
    };
  }, null);

export const cleanCache = cache => {
  const now = new Date().getTime();
  const cacheCallbackKeysToDelete = [];
  cache.forEach((cacheEntryForCallback, callback) => {
    const newCacheEntryForCallback = cleanCacheForCallback(cacheEntryForCallback, now);
    if (newCacheEntryForCallback) {
      cache.set(callback, newCacheEntryForCallback);
    } else {
      cacheCallbackKeysToDelete.push(callback);
    }
  });

  cacheCallbackKeysToDelete.forEach(callback => cache.delete(callback));
};
