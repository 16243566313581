import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ChevronUp } from '@johnlewispartnership/wtr-ingredients/dist/foundations/icons';
import { useScrolled } from 'hooks/use-scrolled';

import { slowlyScrollPageVerticallyToTop } from 'utils/scroll';

import styles from 'components/BackToTop/BackToTop.scss';

const dataAttributes = {
  'data-actiontype': 'redirect',
  'data-origincomponent': 'BackToTop',
  'data-shortdescription': 'Goes to the top of the page',
};

const onClick = event => {
  event.stopPropagation();
  slowlyScrollPageVerticallyToTop();
};

const BackToTop = ({ visiblePosition }) => {
  const { scrolled } = useScrolled(visiblePosition);

  return (
    <div
      className={classNames(styles.backToTop, {
        [styles.active]: scrolled,
      })}
    >
      <button
        className={styles.button}
        onClick={onClick}
        type="button"
        aria-label="Back to top"
        {...dataAttributes}
      >
        <ChevronUp className={styles.icon} size="small" />
      </button>
    </div>
  );
};

BackToTop.propTypes = {
  visiblePosition: PropTypes.number,
};

BackToTop.defaultProps = {
  visiblePosition: 600,
};

export default BackToTop;
